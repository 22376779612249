<template>
  <b-modal
    :id="modalId"
    size="general"
    v-model="modalView"
    centered
    hide-header
    hide-footer
    @hide="onHide"
    @hidden="onHidden"
    @shown="onShown"
  >
    <div class="c--modals_general-modal modal-general-close" v-if="hasCloseButton" :dir="$dir()">
      <button type="button" class="btn" @click.prevent="onPressClose()">
        <span class="airalo-icon-close-round-filled tw-text-5.5 tw-text-neutral-600" data-testid="close-button" />
      </button>
    </div>
    <div :class="modalBodyWrapperClasses" :style="modalBodyWrapperStyle">
      <template v-if="isModalTitle && modalTitle.length > 0">
        <div :class="['general-item-wrapper-header', modalTitleMarginBottomClass]" :data-testid="`${modalTitle}-title`">
          <p>{{ modalTitle }}</p>
        </div>
      </template>
      <slot></slot>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'general-modal',

    props: {
      modalId: {
        type: String,
        required: false,
        default: 'general-modal',
      },

      modalView: {
        type: Boolean,
        required: true,
      },

      height: {
        required: false,
        default: null,
      },

      minHeight: {
        required: false,
        default: 540,
      },

      isModalTitle: {
        type: Boolean,
        required: false,
        default: false,
      },

      modalTitle: {
        type: String,
        required: false,
        default: '',
      },

      modalTitleMarginBottomClass: {
        type: String,
        required: false,
        default: 'mb-20',
      },

      hasCloseButton: {
        type: Boolean,
        required: false,
        default: true,
      },
    },

    computed: {
      deviceType() {
        return this.$store.getters.get_device_types
      },
      innerMinHeight() {
        const stringVal = JSON.stringify(this.minHeight)
        return /px|vh|%/.test(stringVal) ? this.minHeight : `${this.minHeight}px`
      },
      modalBodyWrapperClasses() {
        const classes = [
          'general-item-wrapper',
          'relative',
          'tw-flex',
          'tw-flex-col',
          'tw-h-full',
          'tw-w-full',
          'tw-overflow-y-auto',
        ]

        classes.push(this.hasCloseButton ? 'tw-pt-10' : 'tw-pt-5')
        if (this.height && this.height === 'unset') classes.push('height-unset')

        return classes.join(' ')
      },
      modalBodyWrapperStyle() {
        const style = {
          'min-height': this.innerMinHeight,
        }

        if (this.height && this.height !== 'unset') {
          style.height = `${this.height}px`
        }

        return style
      },
    },

    beforeDestroy() {
      if (process.browser) {
        this.onHidden()
      }
    },

    methods: {
      onHide(evt) {
        if (evt.trigger === 'backdrop' || evt.trigger === 'esc' || evt.trigger === 'event') {
          evt.preventDefault()
          this.$emit(`onCloseModal`)
        }
      },

      onHidden() {
        let htmlEl = document.querySelector('html')
        htmlEl.classList.remove('no-scroll')

        this.removeSyncHeight()

        this.$emit(`onClosedModal`)
      },

      async onShown(evt) {
        await this.syncHeight()
        this.$emit(`onShownModal`)
      },

      onPressClose() {
        this.$bvModal.hide(this.modalId)
        this.$emit(`onPressClose`)
      },

      async syncHeight() {
        document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`)
      },

      removeSyncHeight() {
        document.documentElement.style.removeProperty('--window-inner-height')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .general-item-wrapper {
    &.height-unset {
      @apply tw-h-unset tw-flex-auto;
    }

    &-header {
      border-bottom: 1px solid #eeeeee;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      width: 100%;

      p {
        color: #4a4a4a;
        font-size: 1.3125rem;
        font-weight: 600;
        letter-spacing: -0.3px;
        line-height: 1.1904761;
        margin: 0;
      }
    }
  }

  ::v-deep {
    .modal-general {
      max-width: 540px;

      &-close {
        .btn {
          padding: 0;
          margin: 0;
          display: flex;
          position: absolute;
          right: 8px;
          top: 8px;
          z-index: 10;
          box-shadow: none;
        }

        &[dir='rtl'] {
          .btn {
            left: 5px;
            right: unset;
          }
        }
      }

      .modal-body {
        padding: 0;
        overflow: hidden;
        display: flex;
      }

      @media (max-width: 575px) {
        width: 100vw;
        height: 100%;
        max-width: unset;
        min-height: unset;
        margin: 0;
      }

      .modal-content {
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
        border-radius: 7px;

        @media (max-width: 575px) {
          height: 100%;
          border-radius: 0;
        }
      }
    }
  }
</style>
